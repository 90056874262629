import { CartLine } from '@msdyn365-commerce/retail-proxy';
import { getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import * as Msdyn365 from '@msdyn365-commerce/core';

export const logError = (context: Msdyn365.ICoreContext<any>, message: string, error: any): void => {
    context.telemetry.error(`${message}: ${error}`);
};

export const logWarning = (context: Msdyn365.ICoreContext<any>, message: string): void => {
    context.telemetry.warning(message);
};

export const extractProductIds = (cartlines: CartLine[]): number[] => {
    return cartlines.map(line => line.ProductId).filter((id): id is number => id !== undefined);
};

export const fetchActivePrices = async (
    productIds: number[],
    context: Msdyn365.ICoreContext<{ [x: string]: any }>,
    accountNumber: string
): Promise<any[]> => {
    if (productIds.length === 0) {
        return [];
    }

    try {
        return await getActivePricesAsync(
            { callerContext: context.actionContext },
            {
                ChannelId: context.request.apiSettings.channelId,
                CatalogId: Number(context.request.user.catalogId) || 0
            },
            productIds,
            new Date(),
            accountNumber,
            undefined,
            true
        );
    } catch (error) {
        logError(context, 'Error fetching active prices', error);
        return [];
    }
};

export const attachPricesToCartLines = (cartlines: CartLine[], activePrices: any[]): CartLine[] => {
    // Create a map for faster lookup by ProductId
    const priceMap = new Map(activePrices.map(price => [price.ProductId, price]));

    return cartlines.map(line => {
        const activePrice = priceMap.get(line.ProductId);
        return {
            ...line, // Return a new object to maintain immutability
            Price: activePrice?.CustomerContextualPrice
        };
    });
};

export const fetchCartLinesWithActivePrices = async (
    cartlines: CartLine[],
    context: Msdyn365.ICoreContext<{ [x: string]: any }>,
    accountNumber: string
): Promise<CartLine[]> => {
    if (cartlines.length === 0) {
        logWarning(context, 'No cart lines provided.');
        return [];
    }

    const productIds = extractProductIds(cartlines);
    const activePrices = await fetchActivePrices(productIds, context, accountNumber);
    return attachPricesToCartLines(cartlines, activePrices);
};
